import React, { Component } from 'react';
import { useMap, MapContainer, TileLayer, Pane, Polyline, Marker, Tooltip } from 'react-leaflet';
import { lineInfoStopIcon } from '../leaflet/StopIcons';
import 'leaflet/dist/leaflet.css';

export class LinePathInfoMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isToRecenter: true
        }
    }

    getBounds(path) {
        if (undefined === path) {
            return [{ lat: 41.1483096, lng: -8.6108148 }, { lat: 41.1442942, lng: -8.6105935 }];
        }

        if (undefined === path.shape || null === path.shape || !Array.isArray(path.shape.segments) || 0 === path.shape.segments.length) {
            return [{ lat: path.maxLatitude, lng: path.maxLongitude }, { lat: path.minLatitude, lng: path.minLongitude }];
        }

        return [{ lat: path.shape.maxLatitude, lng: path.shape.maxLongitude }, { lat: path.shape.minLatitude, lng: path.shape.minLongitude }];
    }

    /*This is used to change the Tooltip permanent prop, which is immutable and can't be changed after creating the Tooltip.
    * But since this is used in the key prop, it will trigger the re-render and subsquently change the permanent prop value.
    */
    isPermanent(place) {
        const { selectedPathStop } = this.props;
        return selectedPathStop !== undefined && selectedPathStop.id === place.id
    }

    //------------------------

    renderPath(path) {
        if (undefined === path) {
            return null;
        }

        if (undefined === path.shape || null === path.shape || !Array.isArray(path.shape.segments) || 0 === path.shape.segments.length) {
            return (
                <Polyline
                    key="polyLine-line-info"
                    positions={Array.isArray(path.places) ? path.places.map(place => [place.coordX, place.coordY]) : []}
                    color="#bc8627"
                    eventHandlers={{
                        click: () => {
                            this.handlePathSelection(path);
                        }
                    }} />
            );
        }

        return (
            <React.Fragment>
                {
                    path.shape.segments.map((segment, idx) =>
                        <Polyline
                            key={`polyLine-line-info-${path.code}-${idx}`}
                            positions={segment.breakpoints.map(breakpoint => [breakpoint.latitude, breakpoint.longitude])}
                            color="#bc8627"
                            eventHandlers={{
                                click: () => {
                                    this.handlePathSelection(path);
                                }
                            }} />
                    )
                }
            </React.Fragment>
        );
    }

    renderPathStops(path) {
        if (undefined === path || undefined === path.places) {
            return null;
        }

        return (
            <div>
                {
                    path.places.map((place, idx) =>
                        <Marker
                            key={`marker-${idx}`}
                            position={[place.coordX, place.coordY]}
                            icon={lineInfoStopIcon}>
                            {this.renderTooltip(place)}
                        </Marker>
                    )
                }
            </div>
        );
    }

    renderTooltip(place) {
        const { makeTooltipsPermanent } = this.props;

        if (makeTooltipsPermanent) {
            return (
                <Tooltip key={`tooltip-${place.code}-${this.isPermanent(place)}`} permanent={this.isPermanent(place)}>
                    <span><b>{place.name}</b> ({place.code})</span>
                </Tooltip>
            );
        }

        return (
            <Tooltip key={`tooltip-${place.code}`}>
                <span><b>{place.name}</b> ({place.code})</span>
            </Tooltip>
        );
    }

    render() {
        const { path } = this.props;
        const { isToRecenter } = this.state;
        return (
            <MapContainer className="map-container" bounds={this.getBounds(path)} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                <SetBounds bounds={this.getBounds(path)} isToRecenter={isToRecenter} />
                <Pane>
                    {this.renderPath(path)}
                </Pane>
                <Pane>
                    {this.renderPathStops(path)}
                </Pane>
            </MapContainer>
        );
    }
}

function SetBounds({ bounds, isToRecenter }) {
    const map = useMap();
    if (isToRecenter) {
        map.fitBounds(bounds);
    }
    return null;
}
