import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './NavMenu.css';

export class MenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLabel: false
        };
    }

    expandLabelHandler() {
        this.setState({
            showLabel: true
        });
    }

    collapseLabelHandler() {
        this.setState({
            showLabel: false
        });
    }

    render() {
        const { showLabel } = this.state;
        const { icon, label, to, hidden } = this.props;

        if (!hidden) {
            return (
                <div className="icon-nav-item-link">
                    <LinkContainer className="icon-nav-item-link-container" key={`menu-item-${label.toLowerCase().replace(" ", "-")}`} to={to}>
                        <div className="icon-nav-item-link-content">
                            <div className="icon-nav-item-link-icon"
                                onMouseLeave={() => this.collapseLabelHandler()}
                                onMouseEnter={() => this.expandLabelHandler()} >
                                <div className={icon === 'icon-schedules-by-line' ? `${icon} icon-nav-item-link-icon-dark font-size-30` : `${icon} icon-nav-item-link-icon-dark`}></div>
                            </div>

                            <div className={showLabel ? "icon-nav-item-link-label-show" : "icon-nav-item-link-label-hide"}>
                                {label}
                            </div>
                        </div>
                    </LinkContainer>
                </div>
            );
        } else {
            return null;
        }
    }
}
