import React, { Component } from 'react';
import { Container, Row} from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class TicketInfo extends Component {
    render() {
        return (
            <Container fluid className="mb-5">
                <Row className="mt-5">
                    <h5>{strings.tickets} <br /><br /></h5>
                </Row>
                <Row>
                    <h6>{strings.simpleOnBoard}<br/>{strings.fullAndHalf}</h6>
                    <p>
                        <ul>
                            <li>{strings.fullAndHalfConditionA}</li>
                            <li>{strings.fullAndHalfConditionB}</li>
                            <li>{strings.fullAndHalfConditionC}</li>
                        </ul>
                    </p>
                </Row>
                <Row>
                    <h6>{strings.prebought}</h6>
                    <p>
                        <ul>
                            <li>{strings.preboughtConditionA}</li>
                            <li>{strings.preboughtConditionB}</li>
                            <li>{strings.preboughtConditionC}</li>
                        </ul>
                    </p>
                </Row>
                <Row>
                    <h6>{strings.monthly}</h6>
                    <p>
                        <ul>
                            <li>{strings.monthlyConditionA}</li>
                            <li>{strings.monthlyConditionB}</li>
                            <li>{strings.monthlyConditionC}</li>
                            <li>{strings.monthlyConditionD}</li>
                            <li>{strings.monthlyConditionE}</li>
                        </ul>
                    </p>
                </Row>
                <Row>
                    <h6>{strings.passes418s23}</h6>
                    <p>
                        <ul>
                            <li>{strings.passes418s23ConditionA}</li>
                            <li>{strings.passes418s23ConditionB}</li>
                            <li>{strings.passes418s23ConditionC}</li>
                            <li>{strings.passes418s23ConditionD}</li>
                            <li>{strings.passes418s23ConditionE}</li>
                            <br />
                            <ul>
                                <h7>{strings.pass418}</h7>
                                <p>
                                    <ul>
                                        <li>{strings.pass418ConditionF}</li>
                                    </ul>
                                </p>
                            </ul>
                            <ul>
                                <h7>{strings.passS23}</h7>
                                <p>
                                    <ul>
                                        <li>{strings.passS23ConditionF}</li>
                                    </ul>

                                </p>
                            </ul>
                        </ul>
                    </p>
                    <p>{strings.passes418s23MoreInfo} <br />
                        <a href="docs/passes_estudantes_trimbal.pdf" target="_blank" rel="noopener noreferrer">{strings.passes418s23}</a>
                        <br />
                        <a href="docs/passes_estudantes_trimbal_faqs.pdf" target="_blank" rel="noopener noreferrer">{strings.passes418s23Faqs}</a>
                    </p>
                    <p />
                </Row>
                <Row>
                    <h6>{strings.passCIM}</h6>
                    <p>
                        <ul>
                            <li>{strings.passCIMConditionA}</li>
                            <li>{strings.passCIMConditionB}</li>
                            <li>{strings.passCIMConditionC}</li>
                            <li>{strings.passCIMConditionD}</li>
                            <li>{strings.passCIMConditionE}</li>
                            <li>{strings.passCIMConditionF}</li>
                            <li>{strings.passCIMConditionG}</li>
                        </ul>
                    </p>
                </Row>
                <a href="docs/ticket_info_trimbal.pdf" target="_blank">{strings.ticketsDownload} <br /> <br /></a>
            </Container>
        );
    }
}