import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { strings } from '../../resources/strings';
import { ticketOfficeIcon } from '../leaflet/StopIcons';

export class TicketOffice extends Component {
    render() {
        return (
            <Container fluid className="mb-5" >
                <Row>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader}</b><br />
                            <b>Praça António Raposo Tavares<br />
                                7800 - 426 Beja</b><br />
                            <b>{strings.email}:</b> geral@trimbal.pt<br />
                            <b>{strings.phone}:</b> 284 401 227<br /><br />
                            <p>{strings.contactWorkingDays}<br />
                                {strings.contactSaturdays}<br />
                                {strings.contactSundays}</p>
                        </div>

                        <MapContainer className="map-container-ticket-office" center={[38.009452664380355, -7.859044138256686]} zoom={16} scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                            />
                            <Marker
                                key={`to-rodoviaria-do-alentejo`}
                                position={[38.009452664380355, -7.859044138256686]}
                                icon={ticketOfficeIcon}>

                                <Tooltip>
                                    <strong>Rodoviária do Alentejo</strong>
                                </Tooltip>
                            </Marker>
                        </MapContainer>
                    </Col>
                </Row>
            </Container>
        );
    }
}
